import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';

import css from './ListingPage.module.css';

const SectionFeaturesMaybe = props => {
  const { options, publicData } = props;
  if (!publicData) {
    return null;
  }

  const selectedOptions = publicData && publicData.living ? publicData.living : [];

  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.livingTitle" />
      </h2>
      <PropertyGroup
        id="ListingPage.living"
        className={css.workspace}
        itemClassName={css.livingItem}
        options={options}
        selectedOptions={selectedOptions}
      />
    </div>
  );
};

export default SectionFeaturesMaybe;
