import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import bath from './bath.svg';
import bed from './bed.svg';
import guest from './guest.svg';
import work from './work.svg';

import css from './ListingPage.module.css';

const SectionOverviewMaybe = props => {
  const { publicData } = props;
  if (!publicData) {
    return null;
  }

  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.overviewTitle" />
      </h2>
      <div className={css.amenities}>
          <div className={css.amenity}>
            <img src={guest} alt="guest" className={css.icon}/>
            <span className={css.overview}>{publicData.noOfGuests}</span>
          </div>
          <div className={css.amenity}>
            <img src={bed} alt="bed" className={css.icon}/>
            <span className={css.overview}>{publicData.noOfBedrooms}</span>
          </div>
          <div className={css.amenity}>
            <img src={bath} alt="bath" className={css.icon}/>
            <span className={css.overview}>{publicData.noOfBathrooms}</span>
          </div>
          <div className={css.amenity}>
            <img src={work} alt="work" className={css.icon}/>
            <span className={css.overview}>{publicData.noworkstations}</span>
          </div>
        </div>
    </div>
  );
};

export default SectionOverviewMaybe;
