import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import high from './high.svg';
import medium from './medium.svg';
import low from './low.svg';

import css from './ListingPage.module.css';

const SectionWifiMaybe = props => {
  const { publicData } = props;

  const downloadSpeed = publicData && publicData.downloadSpeed;

  const speedIcon = downloadSpeed > 100 ? high : downloadSpeed > 26 ? medium : low;

  const downloadSpeedText = downloadSpeed > 100 ? 'High' : downloadSpeed > 26 ? 'Medium' : 'Slow';

  return downloadSpeed ? (
    <div className={css.sectionWifi}>
      <h2 className={css.wifiTitle}>
        <FormattedMessage id="ListingPage.wifiTitle" />
      </h2>
      <div className={css.card}>
        <img src={speedIcon} alt="speed" className={css.wifiIcon} />
        <div className={css.wifiInfo}>
          <h3 className={css.wifiName}>
            <FormattedMessage id={`ListingPage.wifiName${downloadSpeedText}Speed`} />
          </h3>
          <p className={css.wifiSpeed}>
            {downloadSpeed} <FormattedMessage id="ListingPage.wifiSpeed" />
          </p>
        </div>
        <div className={css.wifiDescription}>
            <FormattedMessage id={`ListingPage.wifiName${downloadSpeedText}SpeedInfo`} />
        </div>
      </div>
    </div>
  ) : null;
};

export default SectionWifiMaybe;
