import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { WithThreeReviews } from '../../components/Reviews/Reviews.example';
import { IconReviewStar, ReviewRating, Reviews } from '../../components';

import css from './ListingPage.module.css';

const SectionReviews = props => {
  const { reviews, fetchReviewsError } = props;

  const reviewsError = (
    <h2 className={css.errorText}>
      <FormattedMessage id="ListingPage.reviewsError" />
    </h2>
  );

  const averageRating = reviews?.reduce((acc, review) => acc + review.attributes.rating, 0) / reviews?.length || 0;
  const oneStarPercentage = reviews?.filter(review => review.attributes.rating === 1).length / reviews?.length * 100;
  const twoStarPercentage = reviews?.filter(review => review.attributes.rating === 2).length / reviews?.length * 100;
  const threeStarPercentage = reviews?.filter(review => review.attributes.rating === 3).length / reviews?.length * 100;
  const fourStarPercentage = reviews?.filter(review => review.attributes.rating === 4).length / reviews?.length * 100;
  const fiveStarPercentage = reviews?.filter(review => review.attributes.rating === 5).length / reviews?.length * 100;
  
  return (
    <div className={css.sectionReviews}>
      <h2 className={css.reviewsHeading}>
        <FormattedMessage id="ListingPage.reviewsHeading" />
      </h2>
      {fetchReviewsError ? reviewsError : null}
      <div className={css.reviewsResume}>
        <div className={css.resume}>
          <div className={css.resumeHeader}>
            <IconReviewStar
              key={`star-example-1`}
              isFilled={true}
            />
            <span className={css.resumeText}>
              {averageRating.toFixed(1)}
              <span className={css.resumeTextSmall}>
                {reviews?.length > 0 ? reviews?.length > 1 ? ` (${reviews?.length} reviews)` : ` (${reviews?.length} review)` : ' 0 reviews'}
              </span>
            </span>
          </div>
          <div className={css.resumeRow}>
            <ReviewRating
              rating={5}
              className={css.desktopReviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
            <div className={css.progress}>
              <div className={css.progressFill} style={{ width: `${fiveStarPercentage}%` }}></div>
            </div>
          </div>
          <div className={css.resumeRow}>
            <ReviewRating
              rating={4}
              className={css.desktopReviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
            <div className={css.progress}>
              <div className={css.progressFill} style={{ width: `${fourStarPercentage}%` }}></div>
            </div>
          </div>
          <div className={css.resumeRow}>
            <ReviewRating
              rating={3}
              className={css.desktopReviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
            <div className={css.progress}>
              <div className={css.progressFill} style={{ width: `${threeStarPercentage}%` }}></div>
            </div>
          </div>
          <div className={css.resumeRow}>
            <ReviewRating
              rating={2}
              className={css.desktopReviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
            <div className={css.progress}>
              <div className={css.progressFill} style={{ width: `${twoStarPercentage}%` }}></div>
            </div>
          </div>
          <div className={css.resumeRow}>
            <ReviewRating
              rating={1}
              className={css.desktopReviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
            <div className={css.progress}>
              <div className={css.progressFill} style={{ width: `${oneStarPercentage}%` }}></div>
            </div>
          </div>
        </div>
        <div className={css.lastReview}>
          <Reviews reviews={reviews?.length > 0 ? [reviews[reviews?.length - 1]] : []} className={css.lastReviewItem} />
        </div>
      </div>
      <Reviews reviews={reviews} seeMore={true} />
    </div>
  );
};

export default SectionReviews;
