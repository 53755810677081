import React, {useState} from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton } from '../../components';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import config from '../../config';
import { removePostalCodeAndShortenAddress } from '../../util/address';

import city from '../../assets/ListingCard/apartment.png';
import country from '../../assets/ListingCard/park.png';
import mountain from '../../assets/ListingCard/filter_hdr.png';
import beach from '../../assets/ListingCard/beach_access.png';

import css from './ListingPage.module.css';

const SectionHeading = props => {
  const {
    richTitle,
    category,
    locationLabel
  } = props;

  const typeIcon = category === 'countryside' ? country : category === 'mountain' ? mountain : category === 'beach' ? beach : city;
  const typeId = category === 'countryside' ? 'countryside' : category === 'mountain' ? 'mountain' : category === 'beach' ? 'beach' : 'city';
  
  // Split the address to just show city, state and countryside
  // example: CN Tower, 301 Front St W, Toronto, Ontario M5V 2T6, Canada
  // result: Toronto, Ontario, Canada
  const address = removePostalCodeAndShortenAddress(locationLabel?.address);

  return (
    <div className={css.sectionHeading}>
      <div className={css.type}>
        <img src={typeIcon} alt="type" className={css.typeIcon} />
        <span className={css.typeId}>
          <FormattedMessage id={`ListingCard.${typeId}`} />
        </span>
      </div>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <div className={css.author}>
          {address}
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;
