import React, { useEffect, useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Storage } from 'aws-amplify';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ImageCarousel, ResponsiveImage, Modal } from '../../components';

import initial from './initialQuote.svg';
import final from './finalQuote.svg';
import icon from './collections.png';

import css from './ListingPage.module.css';

const { configureAWS } = require('../../aws')
const { UUID } = sdkTypes;

const SectionTips = props => {
  const {
    publicData,
    onManageDisableScrolling
  } = props;

  if (!publicData) {
    return null;
  }

  const { surroundings = [] } = publicData || {}
  const [images, setImages] = useState([])
  const [imageCarouselOpen, setImageCarouselOpen] = useState(false)
  const hasImages = images.length > 1;

  const getSurroundings = async () => {
    let imagesA = []
    for (let i = 0; i < surroundings.length; i++) {
      await Storage.get(`surroundings/${surroundings[i]}`)
        .then(result => {
          imagesA.push({
            id: new UUID(surroundings[i]),
            attributes: {
              variants: {
                "landscape-crop": {
                  url: result,
                  width: 100,
                  height: 100
                }
              }
            },
            type: 'image',
          })
        })
        .catch(err => console.log('error', err))
    }
    setImages(imagesA)
  }

  useEffect(() => {
    configureAWS()
    getSurroundings()
  }, [])

  const viewPhotosButton = hasImages ? (
    <button className={css.viewPhotos} onClick={() => setImageCarouselOpen(true)}>
      <span className={css.viewText}>
        <FormattedMessage id="ListingPage.viewImagesButton" />
      </span>
      <span className={css.viewIcon}>
        <img src={icon} alt="view photos" />
      </span>
    </button>
  ) : null;

  return publicData.nearby ? (
    <div className={css.sectionHost}>
      <h2 className={css.yourHostHeading}>
        <FormattedMessage id="ListingPage.tipsFromTheHost" />
      </h2>
      <div className={css.hostContent}>
        <img src={initial} alt="initial quote" />
        <p className={css.hostText}>
          {publicData.nearby}
        </p>
        <img src={final} alt="final quote" />
      </div>
      <div className={css.imagesWrapperSurroundings}>
        <div className={css.threeToTwoWrapperSurroundings}>
          <div className={css.aspectWrapperSurroundings} onClick={() => {setImageCarouselOpen(true)}}>
            <ResponsiveImage
              rootClassName={css.rootForImageSurroundings}
              alt={"Surroundings of the property"}
              image={images[0]}
              variants={[
                'landscape-crop',
                'landscape-crop2x',
                'landscape-crop4x',
                'landscape-crop6x',
              ]}
            />
          </div>
        </div>
        {viewPhotosButton}
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={() => setImageCarouselOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel
          images={images}
          imageVariants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge', 'landscape-crop']}
        />
      </Modal>
    </div>
  ) : null;
};

export default SectionTips;
