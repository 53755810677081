import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel, ImageCarouselMobile } from '../../components';
import ActionBarMaybe from './ActionBarMaybe';

import icon from './collections.png';
import css from './ListingPage.module.css';

const SectionImages = props => {
  const {
    title,
    listing,
    isOwnListing,
    editParams,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
  } = props;

  const hasImages = listing.images && listing.images.length > 0;
  const firstImage = hasImages ? listing.images[0] : null;
  const secondImage = hasImages ? listing.images[1] : null;

  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = listing.id ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
    </div>
  ) : null;

  const viewPhotosButton = hasImages ? (
    <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
      <span className={css.viewText}>
        <FormattedMessage id="ListingPage.viewImagesButton" />
      </span>
      <span className={css.viewIcon}>
        <img src={icon} alt="view photos" />
      </span>
    </button>
  ) : null;

  return (
    <div className={css.sectionImages}>
      {actionBar}
      <div className={css.imagesWrapper}>
        <div className={css.threeToTwoWrapper}>
          <div className={css.aspectWrapper} onClick={handleViewPhotosClick}>
            <ResponsiveImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={[
                'landscape-crop',
                'landscape-crop2x',
                'landscape-crop4x',
                'landscape-crop6x',
              ]}
            />
          </div>
        </div>
        <div className={css.threeToTwoWrapper}>
          <div className={css.aspectWrapper} onClick={handleViewPhotosClick}>
            <ResponsiveImage
              rootClassName={css.rootForImage}
              alt={title}
              image={secondImage}
              variants={[
                'landscape-crop',
                'landscape-crop2x',
                'landscape-crop4x',
                'landscape-crop6x',
              ]}
            />
          </div>
        </div>
        {viewPhotosButton}
      </div>
      <div className={css.mobileImageCarousel}>
        <ImageCarouselMobile
          images={listing.images}
          imageVariants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
        />
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel
          images={listing.images}
          imageVariants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
        />
      </Modal>
    </div>
  );
};

export default SectionImages;
